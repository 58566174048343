import React from "react"
import Img from "../../components/img"
import { Profiles } from "../../data/assets"
import { Routes } from "../../data/routes"
import Card from "../../components/card"
import CTA from "../../components/CTA"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import SocialProof from "../../components/SocialProof"

const Content = {
  pageName: "About",

  // Values section
  subheader: "Our mission: Empower Every Student to Learn",
  header: "The values we live by",
  description: `Yup is committed to creating a culture that spurs growth,
    transparency, and an environment where everyone's voice is heard.
    By living the values below, we can all L.E.A.D. each and every day
    and foster a workplace that encourages us all to be our best
    selves. This will help us build an excellent product for students,
    families, and educators alike.`,
  valuesHeader: "At Yup we",
  values: [
    "L - listen and learn, to grow",
    "E - empower through honesty and empathy",
    "A - achieve ambitious outcomes by planning and adapting",
    "D - delight and deliver value for students, families, and educators",
  ],

  // Meet the team section
  meetTheTeamHeader: "Meet Members of our Learning Team",
  meetTheTeamText: `Get to know a few of our team members with backgrounds in
    teaching, tutoring, and education administration.`,
  team: [
    {
      name: "Kreg Moccia",
      role: "Director of Academics",
      profile: Profiles.kreg,
      bio: `Kreg graduated from Harvard University with a degree in Economics and
        earned his Masters in Teaching from the Relay Graduate School of Education.
        He taught the full range of high school math subjects before becoming a
        school leader in Brooklyn, NY. There, he coached a team of 10 teachers,
        leading them to some of the highest academic achievement in the city.`,
      linkedIn: "https://www.linkedin.com/in/kregmoccia/",
    },
    {
      name: "Claire Polcrack",
      role: "Education Consultant",
      profile: Profiles.claire,
      bio: `Claire graduated from Susquehanna University with a degree in
        Mathematics & Secondary Education. After graduating, she joined Teach For
        America where she taught upper-level math for five years. Most recently,
        Claire was Achievement Director for five high schools in Connecticut and
        New York. At Yup, Claire helps set the vision and strategy for Yup's
        teaching methods.`,
      linkedIn: "https://www.linkedin.com/in/claire-polcrack-37340411b/",
    },
    {
      name: "Emily Banks",
      role: "Education Consultant",
      profile: Profiles.emily,
      bio: `Emily graduated from Carleton College with a degree in mathematics and
        holds a Master of Arts in Secondary Mathematics Education.  Emily has
        served as a mathematics teacher, curriculum specialist, coach, and most
        recently high school principal.  Under her leadership, Emily's school
        became one of the highest performing high schools in the state,
        earning a gold medal distinction.`,
      linkedIn: "https://www.linkedin.com/in/embanks/",
    },
  ],

  // CTA
  ctaTitle: "Want to learn more?",
  ctaActions: [
    {
      link: Routes.press,
      text: "Press and resources",
    },
    {
      link: Routes.learningFundamentals,
      text: "How we teach",
    },
  ],
}

function AboutSection() {
  // Original TailwindUI component: https://tailwindui.com/components/marketing/sections/content-sections#component-39249d619dcd4a64f3089bf550369572
  return (
    <div className="overflow-hidden">
      <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
          <div>
            <h2 className="text-base text-secondary font-bold tracking-wide uppercase">
              {Content.subheader}
            </h2>
            <h1 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              {Content.header}
            </h1>
          </div>
        </div>
        <div className="mt-8">
          <div className="text-base max-w-prose mx-auto lg:max-w-none text-gray-500">
            <p className="text-lg">{Content.description}</p>
            <hr className="my-4" />
            <p className="text-lg">{Content.valuesHeader}</p>
            <ul className="list-disc ml-8 mt-2">
              {Content.values.map(value => (
                <li key={value}>{value}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

function TeamSection() {
  // Original TailwindUI component: https://tailwindui.com/components/marketing/sections/team-sections#component-0efa5ebc92e2aa72bc2332fcf5578869
  return (
    <div>
      <div className="mx-auto pt-8 pb-12 px-4 max-w-7xl sm:px-6 lg:px-8">
        <div className="space-y-12 lg:grid lg:grid-cols-3 lg:gap-8 lg:space-y-0">
          <div className="space-y-5 sm:space-y-4">
            <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">
              {Content.meetTheTeamHeader}
            </h2>
            <p className="text-xl text-gray-500">{Content.meetTheTeamText}</p>
          </div>
          <div className="lg:col-span-2">
            <ul className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-y-12 sm:space-y-0">
              {Content.team.map(person => (
                <li key={person.name}>
                  <Card className="space-y-4 h-full">
                    <Img
                      src={person.profile}
                      alt={person.name}
                      className="shadow-lg rounded-lg w-full h-48 sm:h-36"
                      cover={true}
                    />
                    <div className="text-lg leading-6 font-medium space-y-1">
                      <h3>{person.name}</h3>
                      <p className="text-secondary">{person.role}</p>
                    </div>
                    <div className="text-lg">
                      <p className="text-gray-500">{person.bio}</p>
                    </div>

                    <ul className="flex space-x-5">
                      {person.linkedIn && (
                        <li>
                          <a
                            href={person.linkedIn}
                            target="_blank"
                            className="text-gray-400 hover:text-gray-500"
                          >
                            <svg
                              className="w-5 h-5"
                              aria-hidden="true"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                            >
                              <path
                                fillRule="evenodd"
                                d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </a>
                        </li>
                      )}
                    </ul>
                  </Card>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

const About = () => {
  return (
    <Layout>
      <Seo
        title={Content.pageName}
        description={Content.subheader}
        route={Routes.about}
      />
      <AboutSection />
      <TeamSection />
      <SocialProof />
      <CTA title={Content.ctaTitle} actions={Content.ctaActions} />
    </Layout>
  )
}

export default About
